<template>
    <v-content>
        <v-breadcrumbs :items="breadcrumbsItem" large></v-breadcrumbs>
        <v-row>
            <v-col
                    cols="2"
                    v-for="title in settings"
                    v-bind:key="title.link"
                    class="viewCard"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <router-link :to="title.link">
                            <HoverCard :cardData="title"></HoverCard>
                            <!--<v-card
            :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="200"
    >
        <v-list-item three-line>
            <v-list-item-content class="white--text">
                <v-row align="center" justify="center">
                    <span>{{title.name}}</span>
                </v-row>-->
                            <!-- <v-divider></v-divider>
     <v-row align="center" justify="center">
         <span>100</span>
     </v-row>-->
                            <!--</v-list-item-content>
        </v-list-item>
    </v-card>-->
                        </router-link>
                    </template>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-content>


    <!--<v-btn
            color="green"
            absolute
            fab
            large
            dark
            bottom
            right
            class="v-btn&#45;&#45;example"
            @click.stop="addModal('CustomerForm')"
    >
        <v-icon> add</v-icon>
    </v-btn>
    <v-dialog
            v-model="snackbar.formModal"
            :fullscreen="dynamicComponent.current === 'CustomerForm'"
            :hide-overlay="dynamicComponent.current === 'CustomerForm'"
            transition="dialog-bottom-transition"
            max-width="590"
            persistent
    >
        <v-card>
            <component :is="dynamicComponent.current"
                       @dialogueClose="dialogueClose"
                       @formResponse="formResponse"
                       :editItem="customerData.updateItem"
            ></component>
        </v-card>
    </v-dialog>
    <v-snackbar
            v-model="snackbar.snackbar"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            top
    >
        {{ snackbar.text }}
        <v-btn
                dark
                text
                @click="snackbar.snackbar = false"
        >
            Close
        </v-btn>
    </v-snackbar>-->
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "AviationSettings",
        computed: {
            settings() {
                return [
                    {
                        name: this.$t('air_details'),
                        link: "/AirDetailList",
                        icon: "mdi-decagram-outline"
                    },
                    {
                        name: this.$t('destination'),
                        link: "/AirDestinationList",
                        icon: "mdi-decagram-outline"
                    },
                    {
                        name: this.$t('service'),
                        link: "/AirServices",
                        icon: "mdi-decagram-outline"
                    },
                    {
                        name: this.$t('package'),
                        link: "AviationPackageList",
                        icon: "mdi-decagram-outline"
                    },
                    {
                        name: this.$t('employee'),
                        link: "AviationEmployeeList",
                        icon: "mdi-decagram-outline"
                    }
                ]
            },
            breadcrumbsItem() {
                return [
                    {
                        text: this.$t('settings'),
                        disabled: true,
                        to: "/AviationSettings",
                        exact: true
                    }
                ]
            }
        },
        data() {
            return {
                snackbar: {
                    formModal: false,
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                },
                customerData: {
                    updateItem: {}
                },
                dynamicComponent: {
                    current: 'AirDestinationForm'
                }
            }
        },
        methods: {
            clickEvent(route) {
                this.$router.push({name: route})
            },
            addModal(route) {
                this.snackbar.formModal = true
                this.dynamicComponent.current = route
            },
            dialogueClose() {
                this.customerData.updateItem = {}
                this.snackbar.formModal = false
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.status ? 'success' : 'red'
                this.snackbar.text = responseObj.text
                this.snackbar.snackbar = true
            }
        },
        components: {
            /*// eslint-disable-next-line vue/no-unused-components
            'AirDestinationForm': () => import(/!* webpackChunkName: "AirDestinationForm" *!/ '../aviation/airDestination/AirDestinationForm'),
            // eslint-disable-next-line vue/no-unused-components
            'AirDetailForm': () => import(/!* webpackChunkName: "AirDetailForm" *!/ '../aviation/airDetail/AirDetailForm'),
            // eslint-disable-next-line vue/no-unused-components
            'AirServicesForm': () => import(/!* webpackChunkName: "AirServicesForm" *!/ '../aviation/airServices/AirServicesForm'),
            // eslint-disable-next-line vue/no-unused-components
            'CustomerForm': () => import(/!* webpackChunkName: "CustomerForm" *!/ '../aviation/customer/CustomerForm')*/
            HoverCard
        }
    }
</script>

<style scoped lang="scss">
    .options {
        border: 2px solid white;
    }

</style>